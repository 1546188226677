<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Super Admin/List</h4>
          <div>
                        <router-link :to="can('admin-create') ? {name:'appAdminCreate'} : '#'">
                          <span :title="can('admin-create') ? 'Create' : 'Create Forbidden'"
                                class="glow d-flex align-items-center">
                            <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
                        </router-link>
          </div>
        </div>

        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center ">
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Show Per Page</label>
                <VueMultiselect v-model="selectedPagination" class="" :options="pagination" :close-on-select="true"
                                label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
              </div>

              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Status</label>
                <VueMultiselect v-model="selectedSuperAdminStatus" class="" :options="superAdminStatus" :close-on-select="true"
                                placeholder="Select super admin status" label="name" track-by="value" :show-labels="false"
                                :allow-empty="false"/>

              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12 col-sm-12 col-md-9  col-lg-9">
                <input v-model="getSuperAdminsParams.where_has_user_query"
                       class="form-control search-super-admin-input-element" type="text"
                       placeholder="Search a super admin by name or email or phone">
              </div>
              <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                        @click="applySuperAdminsFilter(null)">Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'30%'}">NAME</th>
                    <th class="position-relative" :style="{width:'30%'}">EMAIL</th>
                    <th class="position-relative" :style="{width:'25%'}">PHONE</th>
                    <th class="position-relative" :style="{width:'10%'}">STATUS</th>
                    <th :style="{width:'5%'}">ACTIONS</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(superAdmin) in this.superAdmins" :key="superAdmin.id">
                    <td>
                      <span v-if="superAdmin.user" class="super-admin-name">{{
                          `${superAdmin.user.first_name} ${superAdmin.user.last_name}`
                        }}</span>
                    </td>
                    <td>
                      <span v-if="superAdmin.user">{{ superAdmin.user.email }}</span>
                    </td>
                    <td>
                      <span v-if="superAdmin.user">{{ superAdmin.user.phone_number }}</span>
                    </td>
                    <td>
                      <div class="form-group">
                        <div class="controls">
                          <div
                              class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                            <div>
                              <input type="checkbox" class="custom-control-input"
                                     @click="toggleStatus(superAdmin.id, superAdmin.super_admin_status)"
                                     :checked="superAdmin.super_admin_status === 'Active'"
                                     :id="`superAdminStatue-${superAdmin.id}`">
                              <label class="custom-control-label mr-1"
                                     :for="`superAdminStatue-${superAdmin.id}`">
                              </label>
                            </div>
                            <span class="font-medium-1">{{ superAdmin.super_admin_status }}</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-top justify-content-between">
                        <router-link
                            class="text-decoration-none view-edit-delete-icon"
                            style="height: 18px"
                            :title="can('admin-view') ? 'View' : 'View Forbidden'"
                            :to="can('admin-view') ?
                            {
                              name: 'appAdminView',
                              params: { id: superAdmin.id },
                            } : '#'">
                          <i class="bx bx-show"></i>
                        </router-link>
                      </div>
                    </td>

                  </tr>
                  </tbody>
                </table>
                <ListPagination position="right" :pagination-data="paginateLinks"
                                @getClickedPage="applySuperAdminsFilter"/>
              </div>
            </div>
          </div>
        </div>

      </section>
    </template>
  </AppLayout>
</template>

<script>
//component
import AppLayout from '@/layouts/backEnd/AppLayout';
import ListPagination from '@/components/backEnd/pagination/ListPagination';

// mixin
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

// core package
import {
  mapActions,
  mapGetters
} from "vuex";

// package
import VueMultiselect from 'vue-multiselect';

export default {
  name: "SuperAdminList",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
    ListPagination,
    VueMultiselect
  },
  data() {
    return {
      selectedPagination: {
        value: '',
        name: 'Default'
      },

      selectedSuperAdminStatus: {
        value: '',
        name: 'Any'
      },
      getSuperAdminsParams: {
        where_has_user_query: '',
        super_admin_status: '',
        with_relation: ['user'],
        order_by_id: 'DESC',
        paginate: 1,
        pagination: '',
        page: ''
      },
      getSettingsParams: {
        type: ['default'],
        key: ['pagination'],
      },
    };
  },
  watch: {
    selectedPagination(selectedPagination) {
      this.getSuperAdminsParams.pagination = selectedPagination.value;
    },

    selectedSuperAdminStatus(selectedSuperAdminStatus) {
      this.getSuperAdminsParams.super_admin_status = selectedSuperAdminStatus.value;
    },
  },
  computed: {
    ...mapGetters({
      superAdmins: 'appSuperAdmins/superAdmins',
      paginateLinks: 'appSuperAdmins/paginateLinks',
    }),
    superAdminStatus() {
      return [
        {value: '', name: 'Any'},
        {value: 0, name: 'Inactive'},
        {value: 1, name: 'Active'},
      ];
    },
    pagination() {
      return [{value: this.$store.getters['appSettings/settingDefaultPagination'].value, name: 'Default'}, {
        value: 25,
        name: '25 Entries'
      }, {value: 50, name: '50 Entries'}, {value: 100, name: '100 Entries'}];
    },

  },
  methods: {
    ...mapActions({
      resetSuperAdmins: 'appSuperAdmins/resetSuperAdmins',

      getSettings: 'appSettings/getSettings',
      getSuperAdmins: 'appSuperAdmins/getSuperAdmins',
      putSuperAdminOnList: 'appSuperAdmins/putSuperAdminOnList',
    }),

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    toggleStatus(id, superAdminStatus) {
      let status = !(superAdminStatus === 'Active') ? 1 : 0;
      const paramObj = {
        id: id,
        data: {super_admin_status: status},
      };
      console.log(superAdminStatus)
      this.loader(true);
      this.putSuperAdminOnList(paramObj).then(response => {
        this.loader(false);

        if (response.status === 200) {
          const toastObj = {
            message: 'Status Updated Successfully',
            type: 'success'
          };
          this.showToastMessage(toastObj);
        }
      });
    },

    async getSuperAdminList() {
      await this.getSuperAdmins(this.getSuperAdminsParams).then(async (response) => {
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

    async applySuperAdminsFilter(pageNumber) {
      this.loader(true);
      this.getSuperAdminsParams.page = pageNumber;
      await this.getSuperAdminList();
      this.loader(false);
    },
  },

  async mounted() {
    this.loader(true);
    await this.getSettingList();
    await this.getSuperAdminList();
    this.loader(false);
  },

  async beforeUnmount() {
    await this.resetSuperAdmins();
  },
}
</script>

<style scoped>
.super-admin-name {
  color: #5A8DEE;
}

.search-super-admin-input-element {
  min-height: 41px !important;
  padding: 8px 40px 8px 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}

</style>